import { Building2, ListTodo, ReceiptText } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { HorizontalCard, InfoCard } from '~/shadcn/ui/card';
import useOnboardingTaskList, { Step } from '../hooks/useOnboardingTaskList';
import { Button } from '~/shadcn/ui/button';
import PaymentInfoSettingsModal from '~/features/account/components/PaymentInfoSettingsModal/PaymentInfoSettingsModal';

const getCardProgress = (step: Step) => {
  if (step.completed) return 'completed';
  if (step.active) return 'inProgress';
  return 'nextStep';
};
/**
 *
 * @description This component the narrow version of the onboarding task list.
 */
export default function OnboardingTaskList() {
  const { t } = useTranslation('common');
  const [showPaymentInfoSettingsModal, setShowPaymentInfoSettingsModal] = useState(false);
  const { stepsObject } = useOnboardingTaskList({
    setShowPaymentInfoSettingsModal
  });
  const { push } = useRouter();

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-7 tw-max-w-2xl tw-mx-auto tw-px-6 tw-py-6">
        {stepsObject['complete-profile'].active && (
          <HorizontalCard
            text={stepsObject['complete-profile'].description!}
            variant="white"
            className="tw-w-full !tw-bg-blue-100"
          >
            <Button
              variant="default"
              className="!tw-text-base !tw-font-normal !tw-text-white tw-w-full md:tw-w-auto"
              onClick={() => stepsObject['complete-profile'].onClick!()}
              data-testid="complete-profile"
            >
              {stepsObject['complete-profile'].title}
            </Button>
          </HorizontalCard>
        )}
        <div className="tw-w-full tw-grid tw-gap-4 tw-grid-cols-1">
          <InfoCard
            progress={getCardProgress(stepsObject['create-customer'])}
            onClick={
              stepsObject['create-customer'].active
                ? () => {
                    push(stepsObject['create-customer'].link!);
                  }
                : undefined
            }
            footerText={t(`onboarding.${getCardProgress(stepsObject['create-customer'])}`)}
            title={`${t('onboarding.step')} 1`}
            mainText={stepsObject['create-customer'].title}
            description={stepsObject['create-customer'].description!}
            className="tw-w-full"
            defaultIcon={Building2}
            collapsible
            dataTestId="create-customer"
          />
          <InfoCard
            progress={getCardProgress(stepsObject['create-task'])}
            footerText={t(`onboarding.${getCardProgress(stepsObject['create-task'])}`)}
            onClick={
              stepsObject['create-task'].active
                ? () => {
                    push(stepsObject['create-task'].link!);
                  }
                : undefined
            }
            title={`${t('onboarding.step')} 2`}
            mainText={stepsObject['create-task'].title!}
            description={stepsObject['create-task'].description!}
            className="tw-w-full"
            defaultIcon={ListTodo}
            collapsible
            dataTestId="create-task"
          />
          <InfoCard
            progress={getCardProgress(stepsObject['submit-invoice'])}
            footerText={t(`onboarding.${getCardProgress(stepsObject['submit-invoice'])}`)}
            onClick={
              stepsObject['submit-invoice'].active
                ? () => {
                    push(stepsObject['submit-invoice'].link!);
                  }
                : undefined
            }
            title={`${t('onboarding.step')} 3`}
            mainText={stepsObject['submit-invoice'].title!}
            description={stepsObject['submit-invoice'].description!}
            className="tw-w-full"
            defaultIcon={ReceiptText}
            collapsible
            dataTestId="submit-invoice"
          />
        </div>
      </div>
      <PaymentInfoSettingsModal
        open={showPaymentInfoSettingsModal}
        onClose={() => setShowPaymentInfoSettingsModal(false)}
      />
    </>
  );
}
