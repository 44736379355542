import OnboardingTaskList from './OnboardingTaskList';
import { FAQSupportPostQuery as SupportPostQueryType } from '../hooks/graphql/.generated/FAQSupportPostQuery';
import FAQ from '../FAQ';

type OnboardingPageProps = {
  FAQdata?: SupportPostQueryType;
  FAQloading: boolean;
};

/**
 *
 * @description This component is the narrow version of the new onboarding page.
 */
function OnboardingPage({ FAQdata, FAQloading }: OnboardingPageProps) {
  return (
    <div>
      <OnboardingTaskList />
      <div className="tw-mb-5 tw-max-w-2xl tw-px-6 tw-mx-auto">
        <FAQ FAQdata={FAQdata} FAQloading={FAQloading} />
      </div>
    </div>
  );
}

export default OnboardingPage;
