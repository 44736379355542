import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { useState } from 'react';
import { useRouter } from 'next/router';
import useUser from '~/contexts/User/useUser';
import DashboardQuery from '../graphql/DashboardQueryV2.gql';
import { DashboardQueryV2 as DashboardQueryType } from '../graphql/.generated/DashboardQueryV2';
import useOnboardingTaskList from '../hooks/useOnboardingTaskList';
import InvoiceCardList from '~/features/invoices/components/InvoiceCardList';
import TaskCardList from '~/features/tasks/components/TaskCardList';
import CreateCustomerButton from '~/features/customers/components/CreateCustomerButton';
import CreateTaskButton from '~/features/tasks/components/CreateTaskButton';
import CreateInvoiceButton from '~/features/invoices/components/CreateInvoiceButton';
import CreateDeductionButton from '~/features/deductions/components/CreateDeductionButton';
import useResponsiveLayout from '~/features/shared/hooks/useResponsiveLayout';
import DashboardCard from '../../v1/DashboardCard';
import Loader from '~/features/shared/components/Loader';
import PaymentInfoSettingsModal from '~/features/account/components/PaymentInfoSettingsModal/PaymentInfoSettingsModal';
import OnboardingPage from './OnboardingPage';

const skipOnboardingReferralList = ['gordon'];

export default function Dashboard() {
  const { t } = useTranslation('invoices');
  const { t: tCommon } = useTranslation();
  const [showPaymentInfoSettingsModal, setShowPaymentInfoSettingsModal] = useState(false);
  const { showTodoList, loading: loadingOnboardingTaskList } = useOnboardingTaskList({
    setShowPaymentInfoSettingsModal
  });

  const { id: userId, displayName, referral } = useUser();
  const { isMobile } = useResponsiveLayout();

  const {
    data,
    loading: loadingDashboard,
    error
  } = useQuery<DashboardQueryType>(DashboardQuery, {
    variables: { userId },
    fetchPolicy: 'no-cache',
    skip: !!showTodoList
  });

  const {
    query: { show_onboarding: showOnboarding }
  } = useRouter();

  if (loadingDashboard || loadingOnboardingTaskList) {
    return (
      <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-relative">
        <Loader />
      </div>
    );
  }

  const referralTag = referral?.trim().toLocaleLowerCase();
  const skipOnboarding = referralTag && skipOnboardingReferralList.includes(referralTag);

  if ((showTodoList && !skipOnboarding) || showOnboarding) {
    return <OnboardingPage />;
  }

  return (
    <>
      <div className="tw-w-full tw-px-6 tw-py-7">
        <div className="page-header">
          <h1>{tCommon('headers.welcomeBack', { displayName })}</h1>
        </div>
        {error && (
          <div className="alert alert-danger">
            There was an error loading the dashboard. Please contact a member of the support team.
          </div>
        )}
        <div className="row g-1 mt-1">
          <div className="col m-0">
            {data?.dashboard?.draft && (
              <DashboardCard
                title={t('statuses.DRAFT')}
                badgeClassName="bg-secondary"
                count={data.dashboard.draft.count}
                amount={data.dashboard.draft.amount}
                type="invoice"
              />
            )}
          </div>
          <div className="col m-0">
            {data?.dashboard?.open && (
              <DashboardCard
                title={t('statuses.OPEN')}
                badgeClassName="bg-primary"
                count={data.dashboard.open.count}
                amount={data.dashboard.open.amount}
                type="invoice"
              />
            )}
          </div>
          <div className="col m-0">
            {data?.dashboard?.paidOut && (
              <DashboardCard
                title={t('headers.paidAmountDkk')}
                badgeClassName="bg-success"
                count={data.dashboard.paidOut.count}
                amount={data.dashboard.paidOut.amount}
                type="payment"
              />
            )}
          </div>
        </div>
        <div className="card">
          <div className={classNames('mb-1', { 'text-end pe-1': !isMobile, 'row p-1 py-0 g-1': isMobile })}>
            <div className={classNames({ 'col-6': isMobile, 'd-inline ms-1': !isMobile })}>
              <CreateCustomerButton className={classNames('mt-1', { ' w-100': isMobile })} />
            </div>
            <div className={classNames({ 'col-6': isMobile, 'd-inline ms-1': !isMobile })}>
              <CreateTaskButton className={classNames('mt-1', { ' w-100': isMobile })} />
            </div>
            <div className={classNames({ 'col-6': isMobile, 'd-inline ms-1': !isMobile })}>
              <CreateInvoiceButton className={classNames('mt-1', { ' w-100': isMobile })} />
            </div>
            <div className={classNames({ 'col-6': isMobile, 'd-inline ms-1': !isMobile })}>
              <CreateDeductionButton className={classNames('mt-1', { ' w-100': isMobile })} />
            </div>
          </div>
        </div>
        {data && data.openInvoices.totalCount > 0 ? (
          <div>
            <div className="clearfix mb-1">
              <h2>{tCommon('headers.openInvoices')}</h2>
            </div>
            <InvoiceCardList userId={userId} openInvoicesOnly />
          </div>
        ) : (
          <div className="card mb-3">
            <div className="text-center py-3 text-muted">
              <div className="h3">{tCommon('messages.noOpenInvoices')}</div>
            </div>
          </div>
        )}
        {data && data.openTasks.totalCount > 0 ? (
          <div>
            <div className="clearfix mb-1">
              <h2>{tCommon('headers.openTasks')}</h2>
            </div>
            <TaskCardList userId={userId} openTasksOnly />
          </div>
        ) : (
          <div className="card mb-3">
            <div className="text-center py-3 text-muted">
              <div className="h3">{tCommon('messages.noOpenTasks')}</div>
            </div>
          </div>
        )}
      </div>
      <PaymentInfoSettingsModal
        open={showPaymentInfoSettingsModal}
        onClose={() => setShowPaymentInfoSettingsModal(false)}
      />
    </>
  );
}
