import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { FAQSupportPostQuery as SupportPostQueryType } from '../hooks/graphql/.generated/FAQSupportPostQuery';
import Loader from '~/features/shared/components/Loader';
import { AccordionContent, AccordionItem, AccordionTrigger, Accordion } from '~/shadcn/ui/accordion';
import cn from '~/shadcn/utils';

type FAQAccordionProps = {
  data?: SupportPostQueryType;
  loading: boolean;
  className?: string;
};
function FAQAccordion({ data, loading, className }: FAQAccordionProps) {
  const { t } = useTranslation('common');
  if (loading) return <Loader />;
  if (!data) return null;

  return (
    <Accordion type="multiple" className={cn('w-full', className)}>
      {data.faqEntries.edges.map((edge) => (
        <AccordionItem key={edge.node.uuid} value={edge.node.uuid}>
          <AccordionTrigger className="tw-font-semibold tw-pb-5">{edge.node.title}</AccordionTrigger>
          <AccordionContent className="tw-font-normal">
            <p>{edge.node.body}</p>
            {edge.node.link && (
              <Link href={edge.node.link} className="tw-text-blue-500 tw-font-medium" target="_blank">
                {t('onboarding.findOut')}
              </Link>
            )}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default FAQAccordion;
