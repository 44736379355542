import PageHead from '../shared/components/PageHead';
import useFeatures from '../shared/hooks/useFeatures';
import DashboardV1 from './v1/Dashboard';
import DashboardV2 from './v2/Dashboard';
import { FEATURE_VALUES } from '~/constants/features';

function Dashboard() {
  const { getValue } = useFeatures();
  const onboardingExperiment = getValue(FEATURE_VALUES.ONBOARDING_EXPERIMENT);
  const DashboardComponent =
    onboardingExperiment === 'v3_13a' || onboardingExperiment === 'v3_13b' ? DashboardV2 : DashboardV1;

  return (
    <div className="container-md">
      <PageHead namespace="common" translationKey="menu.overview" />
      <DashboardComponent />
    </div>
  );
}

export default Dashboard;
