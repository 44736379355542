import OnboardingPageA from '../v2a/OnboardingPage';
import OnboardingPageB from '../v2b/OnboardingPage';
import useFAQSupportPosts from '../hooks/useFAQSupportPosts';
import useFeatures from '~/features/shared/hooks/useFeatures';
import { FEATURE_VALUES } from '~/constants/features';

function OnboardingPage() {
  const { getValue } = useFeatures();
  const { data: FAQdata, loading: FAQloading } = useFAQSupportPosts();
  const wideVersion = getValue(FEATURE_VALUES.ONBOARDING_EXPERIMENT) === 'v3_13a';

  const OnboardingPageComponent = wideVersion ? OnboardingPageA : OnboardingPageB;

  return <OnboardingPageComponent FAQdata={FAQdata} FAQloading={FAQloading} />;
}

export default OnboardingPage;
