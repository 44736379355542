/* eslint-disable react/function-component-definition */
import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Dashboard from '~/features/dashboard/';

type PageProps = {
  locale: string;
};

const Home: NextPage = () => <Dashboard />;

export const getStaticProps = async ({ locale }: PageProps) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'tasks', 'invoices', 'users', 'rewards']))
  }
});

export default Home;
