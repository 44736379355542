import { useQuery } from '@apollo/client';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import DashboardCard from '../DashboardCard';
import DashboardQuery from './graphql/DashboardQuery.gql';
import { DashboardQuery as DashboardQueryType } from './graphql/.generated/DashboardQuery';
import OnboardingTaskList from './OnboardingTaskList';
import useUser from '~/contexts/User/useUser';
import InvoiceCardList from '~/features/invoices/components/InvoiceCardList';
import TaskCardList from '~/features/tasks/components/TaskCardList';

const skipOnboardingReferralList = ['gordon'];

export default function Dashboard() {
  const { id: userId, displayName, referral } = useUser();
  const { data, loading, error } = useQuery<DashboardQueryType>(DashboardQuery, {
    variables: { userId },
    fetchPolicy: 'no-cache'
  });

  const {
    query: { show_onboarding: showOnboarding }
  } = useRouter();

  const { t } = useTranslation('invoices');
  const { t: tCommon } = useTranslation();

  if (loading) {
    return (
      <div className="row">
        <div className="col">
          <ContentLoader speed={2} width="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect width="100%" height="100" />
          </ContentLoader>
        </div>
        <div className="col">
          <ContentLoader speed={2} width="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect width="100%" height="100" />
          </ContentLoader>
        </div>
        <div className="col">
          <ContentLoader speed={2} width="100%" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect width="100%" height="100" />
          </ContentLoader>
        </div>
      </div>
    );
  }

  const referralTag = referral?.trim().toLocaleLowerCase();
  const hasInvoice = (data?.invoices?.totalCount ?? 0) > 0;
  const skipOnboarding = referralTag && skipOnboardingReferralList.includes(referralTag);

  if ((!hasInvoice && !skipOnboarding) || showOnboarding) {
    return <OnboardingTaskList />;
  }

  return (
    <>
      <div className="page-header">
        <h1>{tCommon('headers.welcomeBack', { displayName })}</h1>
      </div>
      {error && (
        <div className="alert alert-danger">
          There was an error loading the dashboard. Please contact a member of the support team.
        </div>
      )}
      <div className="row g-1 mt-1">
        <div className="col m-0">
          {data?.dashboard?.draft && (
            <DashboardCard
              title={t('statuses.DRAFT')}
              badgeClassName="bg-secondary"
              count={data.dashboard.draft.count}
              amount={data.dashboard.draft.amount}
              type="invoice"
            />
          )}
        </div>
        <div className="col m-0">
          {data?.dashboard?.open && (
            <DashboardCard
              title={t('statuses.OPEN')}
              badgeClassName="bg-primary"
              count={data.dashboard.open.count}
              amount={data.dashboard.open.amount}
              type="invoice"
            />
          )}
        </div>
        <div className="col m-0">
          {data?.dashboard?.paidOut && (
            <DashboardCard
              title={t('headers.paidAmountDkk')}
              badgeClassName="bg-success"
              count={data.dashboard.paidOut.count}
              amount={data.dashboard.paidOut.amount}
              type="payment"
            />
          )}
        </div>
      </div>
      {data && data.openInvoices.totalCount > 0 ? (
        <div>
          <div className="clearfix mb-1">
            <h2>{tCommon('headers.openInvoices')}</h2>
          </div>
          <InvoiceCardList userId={userId} openInvoicesOnly />
        </div>
      ) : (
        <div className="card mb-3">
          <div className="text-center py-3 text-muted">
            <div className="h3">{tCommon('messages.noOpenInvoices')}</div>
          </div>
        </div>
      )}
      {data && data.openTasks.totalCount > 0 ? (
        <div>
          <div className="clearfix mb-1">
            <h2>{tCommon('headers.openTasks')}</h2>
          </div>
          <TaskCardList userId={userId} openTasksOnly />
        </div>
      ) : (
        <div className="card mb-3">
          <div className="text-center py-3 text-muted">
            <div className="h3">{tCommon('messages.noOpenTasks')}</div>
          </div>
        </div>
      )}
    </>
  );
}
