import FAQ from '../FAQ';
import { FAQSupportPostQuery as SupportPostQueryType } from '../hooks/graphql/.generated/FAQSupportPostQuery';
import OnboardingTaskList from './OnboardingTaskList';

type OnboardingPageProps = {
  FAQdata?: SupportPostQueryType;
  FAQloading: boolean;
};
/**
 *
 * @description This component is the wide version of the new onboarding page.
 */
function OnboardingPage({ FAQdata, FAQloading }: OnboardingPageProps) {
  return (
    <div>
      <OnboardingTaskList />
      <div className="tw-mb-5 tw-w-full tw-px-6">
        <FAQ FAQdata={FAQdata} FAQloading={FAQloading} />
      </div>
    </div>
  );
}

export default OnboardingPage;
