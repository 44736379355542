import { Building2, ListTodo, ReceiptText, Smile } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { HorizontalCard, InfoCard } from '~/shadcn/ui/card';
import useOnboardingTaskList, { Step } from '../hooks/useOnboardingTaskList';
import { Button } from '~/shadcn/ui/button';
import PaymentInfoSettingsModal from '~/features/account/components/PaymentInfoSettingsModal/PaymentInfoSettingsModal';

const getCardProgress = (step: Step) => {
  if (step.completed) return 'completed';
  if (step.previousIsActive && step.active) return 'nextStep';
  if (step.active) return 'inProgress';
  return 'locked';
};
/**
 *
 * @description This component is the wide version of the onboarding task list.
 */
export default function OnboardingTaskList() {
  const { t } = useTranslation('common');
  const [showPaymentInfoSettingsModal, setShowPaymentInfoSettingsModal] = useState(false);
  const { push } = useRouter();
  const { stepsObject } = useOnboardingTaskList({
    setShowPaymentInfoSettingsModal
  });

  return (
    <>
      <div className="tw-w-full tw-mb-7">
        <div className="tw-flex tw-flex-col tw-gap-7 tw-w-full tw-px-6 tw-pt-6">
          {stepsObject['complete-profile'].active && (
            <HorizontalCard
              text={stepsObject['complete-profile'].description!}
              Icon={Smile}
              variant="white"
              className="tw-w-full !tw-bg-blue-100"
            >
              <Button
                data-testid="complete-profile"
                variant="default"
                className="!tw-text-base !tw-font-normal !tw-text-white tw-w-full md:tw-w-auto"
                onClick={() => stepsObject['complete-profile'].onClick!()}
              >
                {stepsObject['complete-profile'].title}
              </Button>
            </HorizontalCard>
          )}
          <div className="tw-w-full tw-grid tw-gap-4 lg:tw-grid-cols-3 tw-grid-cols-1">
            <InfoCard
              progress={getCardProgress(stepsObject['create-customer'])}
              onClick={
                stepsObject['create-customer'].active
                  ? () => {
                      push(stepsObject['create-customer'].link!);
                    }
                  : undefined
              }
              footerText={t(`onboarding.${getCardProgress(stepsObject['create-customer'])}`)}
              title={`${t('onboarding.step')} 1`}
              mainText={stepsObject['create-customer'].title}
              description={stepsObject['create-customer'].description!}
              className="tw-w-full"
              defaultIcon={Building2}
              dataTestId="create-customer"
            />
            <InfoCard
              progress={getCardProgress(stepsObject['create-task'])}
              footerText={t(`onboarding.${getCardProgress(stepsObject['create-task'])}`)}
              onClick={
                stepsObject['create-task'].active
                  ? () => {
                      push(stepsObject['create-task'].link!);
                    }
                  : undefined
              }
              title={`${t('onboarding.step')} 2`}
              mainText={stepsObject['create-task'].title!}
              description={stepsObject['create-task'].description!}
              dataTestId="create-task"
              className="tw-w-full"
              defaultIcon={ListTodo}
            />
            <InfoCard
              progress={getCardProgress(stepsObject['submit-invoice'])}
              footerText={t(`onboarding.${getCardProgress(stepsObject['submit-invoice'])}`)}
              onClick={
                stepsObject['submit-invoice'].active
                  ? () => {
                      push(stepsObject['submit-invoice'].link!);
                    }
                  : undefined
              }
              title={`${t('onboarding.step')} 3`}
              mainText={stepsObject['submit-invoice'].title!}
              description={stepsObject['submit-invoice'].description!}
              dataTestId="submit-invoice"
              className="tw-w-full"
              defaultIcon={ReceiptText}
            />
          </div>
        </div>
      </div>
      <PaymentInfoSettingsModal
        open={showPaymentInfoSettingsModal}
        onClose={() => setShowPaymentInfoSettingsModal(false)}
      />
    </>
  );
}
