import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { Formatter } from '@factofly/formatting';
import { InvoiceStatusEnum } from '~/.generated/globalTypes';
import useUser from '~/contexts/User/useUser';
import CardItem from '~/features/shared/components/CardItem';
import EditButton from '~/features/shared/components/EditButton';
import CardActions from '~/features/cards/components/CardActions';
import CardMenu from '~/features/cards/components/CardMenu';
import InvoiceStatusButtons from '../InvoiceStatusButtons';
import { InvoiceSearchQuery_items_edges_node as InvoiceNode } from './graphql/.generated/InvoiceSearchQuery';
import InvoiceStatusBar from '../InvoiceStatusBar';
import ViewEconomicInvoiceButton from '../ViewEconomicInvoiceButton';
import SearchCardHeader from '~/features/search/components/SearchCardHeader';
import InvoiceBadges from '../InvoiceBadges';

type InvoiceSearchCardProps = {
  invoice: InvoiceNode;
  showUser?: boolean;
  allowContractDownload?: boolean;
};

export default function InvoiceSearchCard({
  invoice,
  showUser,
  allowContractDownload
}: InvoiceSearchCardProps) {
  const { push } = useRouter();
  const { t } = useTranslation('invoices');
  const { isAdmin, locale } = useUser();

  const menuVisible = allowContractDownload || (isAdmin && invoice.status !== InvoiceStatusEnum.PAID);

  const clickHandler = useCallback(() => {
    push(`/invoices/${invoice.id}`);
  }, [invoice.id, push]);

  const keyboardHandler = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        clickHandler();
      }
    },
    [clickHandler]
  );

  const statusBadges = (
    <InvoiceBadges
      className="m-0 ms-1"
      invoice={{
        status: invoice.status,
        task: { status: invoice.task?.status },
        overdue: invoice.overdue,
        earlyPayment: invoice.earlyPayment
      }}
    />
  );

  return (
    <div className="card mb-1" onClick={clickHandler} onKeyDown={keyboardHandler} role="button" tabIndex={0}>
      <SearchCardHeader
        id={invoice.id}
        itemNumber={invoice.visibleId}
        title={invoice.customer.name + (invoice.reference ? `: ${invoice.reference}` : '')}
        badge={statusBadges}
      />
      <div className="card-body p-2 py-1 pt-0">
        <InvoiceStatusBar
          invoice={{
            status: invoice.status,
            task: { status: invoice.task?.status },
            overdue: invoice.overdue,
            earlyPayment: invoice.earlyPayment
          }}
        />
        <div className="row">
          {showUser && (
            <div className="col-md">
              <CardItem
                className="mb-1"
                label={t('labels.user')}
                value={<Link href={`/admin/users/${invoice.user.id}`}>{invoice.user.displayName}</Link>}
              />
            </div>
          )}
          <div className="col-md">
            <CardItem
              className="mb-1"
              label={t('labels.invoiceDate')}
              value={Formatter.formatDate(invoice.invoiceDate, locale)}
            />
          </div>
          <div className="col-md">
            <CardItem
              className="mb-1"
              label={
                <>
                  {t('labels.amount')}
                  <span className="text-muted"> ({t('labels.excludingVat')})</span>
                </>
              }
              value={Formatter.formatCurrency(invoice.totalPrice, 'da', invoice.currency)}
            />
          </div>
          {isAdmin && (
            <div className="col-md">
              <CardItem
                className="mb-1"
                label={
                  <>
                    {t('labels.amount')}
                    <span className="text-muted"> ({t('labels.includingVat')})</span>
                  </>
                }
                value={Formatter.formatCurrency(invoice.totalPriceWithVat, 'da', invoice.currency)}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md">
            <CardActions>
              <InvoiceStatusButtons
                invoiceId={invoice.id}
                invoiceStatus={invoice.status}
                buttonClassName="btn-sm ms-1"
                groupClassName="input-group-sm ms-1"
                invoiceTask={invoice.task}
              />
              {isAdmin && !!invoice.invoiceUrl && (
                <ViewEconomicInvoiceButton showTitle={false} url={invoice.invoiceUrl} />
              )}
              {(invoice.status === InvoiceStatusEnum.DRAFT || isAdmin) && (
                <EditButton href={`/invoices/${invoice.id}/edit`} className="btn-sm ms-1" showTitle={false} />
              )}
              {menuVisible && (
                <CardMenu>
                  {allowContractDownload && (
                    <Link className="btn" href={`/api/invoice-contract/${invoice.id}`}>
                      <i className="uil-file-shield-alt me-0" /> {t('buttons.downloadContractAppendix')}
                    </Link>
                  )}
                </CardMenu>
              )}
            </CardActions>
          </div>
        </div>
      </div>
    </div>
  );
}
