import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useMemo, useRef, useState } from 'react';
import useUser from '~/contexts/User/useUser';
import useWindowDimensions from '~/features/shared/hooks/useWindowDimensions';
import ProgressChart from './ProgressChart';
import { OnboardingQuery as OnboardingQueryType } from './graphql/.generated/OnboardingQuery';
import OnboardingQuery from './graphql/OnboardingQuery.gql';
import useResponsiveLayout from '~/features/shared/hooks/useResponsiveLayout';
import PaymentInfoSettingsModal from '~/features/account/components/PaymentInfoSettingsModal/PaymentInfoSettingsModal';

export default function OnboardingTaskList() {
  const { id: userId, firstName, email } = useUser();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { isMobile, isTablet } = useResponsiveLayout();
  const [showPaymentInfoSettingsModal, setShowPaymentInfoSettingsModal] = useState(false);

  const { data, error } = useQuery<OnboardingQueryType>(OnboardingQuery, {
    variables: { userId },
    fetchPolicy: 'no-cache'
  });

  const indicatorWidth = isMobile ? 30 : 40;

  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const profileComplete =
    !!data?.currentUser.bankAccountHidden && !!data.currentUser.taxInfoHidden && !!data.currentUser.address;
  const hasCustomer = (data?.customers.totalCount ?? 0) > 0;
  const hasTask = (data?.tasks.totalCount ?? 0) > 0;
  const hasInvoice = (data?.invoices.totalCount ?? 0) > 0;
  const draftInvoiceId = data?.draftInvoices?.edges?.[0]?.node?.id;

  const steps = useMemo(
    () => [
      {
        id: 'complete-profile',
        title: t('onboarding.completeProfile.title'),
        description: t('onboarding.completeProfile.description'),
        onClick: () => {
          setShowPaymentInfoSettingsModal(true);
        },
        completed: profileComplete,
        active: !profileComplete,
        indicatorRef: ref0
      },
      {
        id: 'create-customer',
        title: t('onboarding.createCustomer.title'),
        description: t('onboarding.createCustomer.description'),
        link: '/customers/new?return_url=/',
        completed: hasCustomer,
        active: !hasCustomer,
        indicatorRef: ref1
      },
      {
        id: 'create-task',
        title: t('onboarding.createTask.title'),
        description: t('onboarding.createTask.description'),
        link: '/tasks/new?return_url=/',
        completed: hasTask,
        active: hasCustomer && !hasTask,
        indicatorRef: ref2
      },
      {
        id: 'submit-invoice',
        title: t('onboarding.submitInvoice.title'),
        description: t('onboarding.submitInvoice.description'),
        link: draftInvoiceId ? `/invoices/${draftInvoiceId}` : '/invoices/new?return_url=/&submit_modal=true',
        completed: hasInvoice,
        active: hasTask && !hasInvoice,
        indicatorRef: ref3
      },
      {
        id: 'complete',
        icon: 'uil-star',
        title: t('onboarding.complete.title'),
        description: t('onboarding.complete.description'),
        completed: false,
        active: hasInvoice,
        indicatorRef: ref4
      }
    ],
    [t, hasInvoice, hasCustomer, hasTask, profileComplete, draftInvoiceId]
  );

  const progress = useMemo(() => steps.filter((x) => x.completed).length / steps.length, [steps]);

  useEffect(() => {
    const c = document.getElementById('myCanvas') as HTMLCanvasElement;
    const ctx = c.getContext('2d');

    if (!ctx) return;

    const indicatorOffset = indicatorWidth / 2;

    const canvasBounds = c.getBoundingClientRect();
    const rects = steps
      .map((x) => (x.indicatorRef!.current! as HTMLElement).getBoundingClientRect())
      .map(({ x, y }) => ({
        x: x - canvasBounds.x + indicatorOffset,
        y: y - canvasBounds.y + indicatorOffset
      }));

    ctx.clearRect(0, 0, c.width, c.height);
    ctx.lineWidth = 5;

    ctx.beginPath();

    rects.forEach((rect, i) => {
      if (i < rects.length - 1 && steps[i].completed && steps[i + 1].completed) {
        ctx.strokeStyle = '#08a77a';
        ctx.moveTo(rect.x, rect.y + indicatorOffset);
        ctx.lineTo(rects[i + 1].x, rects[i + 1].y - indicatorOffset);
        ctx.stroke();
      }
    });

    ctx.beginPath();

    rects.forEach((rect, i) => {
      if (i < rects.length - 1 && (!steps[i].completed || !steps[i + 1].completed)) {
        ctx.strokeStyle = '#eee';
        ctx.moveTo(rect.x, rect.y + indicatorOffset);
        ctx.lineTo(rects[i + 1].x, rects[i + 1].y - indicatorOffset - 1);
        ctx.stroke();
      }
    });
  }, [steps, width, height, indicatorWidth]);

  return (
    <div className="container-md mt-3">
      {error && (
        <div className="alert alert-danger">
          There was an error loading the onboarding task list. Please contact a member of the support team.
        </div>
      )}
      <canvas id="myCanvas" style={{ position: 'absolute', zIndex: 1 }} width="100" height="1000" />
      <div className="card">
        <div className="card-body p-2 p-md-3">
          <div className="row">
            <div className={classNames('col-md-8', { 'text-center': isMobile || isTablet })}>
              <h1>{t('onboarding.title', { firstName: firstName || email })}</h1>
              <p>{t('onboarding.subtitle')}</p>
            </div>
            <div className="col-md-4">
              <div className="row mt-2 mb-2">
                <div className="col text-end">
                  <h4 className="mb-0 mt-1">{t('onboarding.progress')}</h4>
                  <p className="progress-amount h3 fw-normal mt-0">{Math.floor(progress * 100)}%</p>
                </div>
                <div className="col-6 col-md-4 me-2">
                  <ProgressChart progress={progress} width={60} lineWidth={20} />
                </div>
              </div>
            </div>
          </div>
          {steps.map((step, i) => (
            <div
              key={step.title}
              className={classNames('card step mb-1', { active: step.active, completed: step.completed })}
            >
              <div className="row g-0">
                <div className="col-2 col-sm-1">
                  <div
                    ref={step.indicatorRef}
                    className={classNames('step-indicator ms-md-2 mt-2 rounded-circle text-center', {
                      'bg-success': step.completed,
                      'bg-light': !step.completed,
                      'm-1': isMobile
                    })}
                  >
                    {step.completed && <i className="uil-check text-light" />}
                    {!step.completed &&
                      (step.icon ? (
                        <i className={classNames(step.icon, 'text-light')} />
                      ) : (
                        <span className="text-muted">{i + 1}</span>
                      ))}
                  </div>
                </div>
                <div className={classNames('col', { 'text-muted': !step.active })}>
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description pe-1">{step.description}</p>
                </div>
                <div className="col-md-2">
                  <div className="section-buttons text-end mt-1 me-2 mb-1">
                    {step.active && !step.completed && step.link && (
                      <Link href={step.link} legacyBehavior>
                        <a className={classNames('btn btn-primary', step.id)} data-testid={step.id}>
                          <i className="uil-angle-right-b me-0" />
                        </a>
                      </Link>
                    )}
                    {step.active && !step.completed && step.onClick && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={step.onClick}
                        data-testId={step.id}
                      >
                        <i className="uil-angle-right-b me-0" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <PaymentInfoSettingsModal
        open={showPaymentInfoSettingsModal}
        onClose={() => setShowPaymentInfoSettingsModal(false)}
      />
      <style jsx>{`
        .step.card {
          border: 1px solid #eee;
        }

        .step.card.completed .step-title {
          text-decoration: line-through;
        }

        .step.card:not(.completed):not(.active) .step-title,
        .step.card:not(.completed):not(.active) .step-description {
          color: #ccc !important;
        }

        .step-indicator {
          width: ${indicatorWidth}px;
          height: ${indicatorWidth}px;
          border: 1px solid #eee;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
          font-size: 1.4rem;
          font-weight: bold;
        }
        .step-indicator.bg-success {
          border: 1px solid #08a77a;
        }

        @media (max-width: 600px) {
          .card.step {
            box-shadow: none;
            border: 0;
            padding: 0;
            margin: 0;
          }
        }
      `}</style>
    </div>
  );
}
