import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import FAQAccordion from './FAQAccordion';
import { FAQSupportPostQuery as SupportPostQueryType } from '../hooks/graphql/.generated/FAQSupportPostQuery';
import Loader from '~/features/shared/components/Loader';

type OnboardingPageProps = {
  FAQdata?: SupportPostQueryType;
  FAQloading: boolean;
};
function FAQ({ FAQdata, FAQloading }: OnboardingPageProps) {
  const { t } = useTranslation('common');
  const FAQHeader = FAQdata?.faqHeaderEntries.edges[0].node;
  if (FAQloading) return <Loader />;
  return (
    <>
      <div className="tw-pb-7 tw-mb-0 tw-flex tw-items-center">
        <h2 className="tw-archivo-black-font tw-text-2xl ">{FAQHeader?.title ?? t('onboarding.faq')}</h2>
        <Link href={FAQHeader?.link!} className="tw-text-blue-500 tw-font-medium tw-ml-auto" target="_blank">
          {FAQHeader?.body ?? t('onboarding.findOut')}
        </Link>
      </div>
      <FAQAccordion data={FAQdata} loading={FAQloading} className="tw-border-t" />
    </>
  );
}

export default FAQ;
