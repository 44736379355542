import { useQuery } from '@apollo/client';
import { SupportPostType } from '@factofly/database';
import useUser from '~/contexts/User/useUser';
import SupportPostQuery from './graphql/FAQSupportPostQuery.gql';
import { FAQSupportPostQuery as SupportPostQueryType } from './graphql/.generated/FAQSupportPostQuery';
import { LanguageEnum } from '~/.generated/globalTypes';

function useFAQSupportPosts() {
  const { locale } = useUser();
  const { data, loading } = useQuery<SupportPostQueryType>(SupportPostQuery, {
    variables: {
      whereFAQEntries: {
        type: {
          equals: SupportPostType.FAQ
        },
        language: {
          equals: locale === 'en-GB' ? LanguageEnum.EN : LanguageEnum.DA
        }
      },
      firstFAQEntries: 10,
      skipFAQEntries: 0,
      orderByFAQEntries: { orderId: 'desc' },
      whereFAQHeaderEntries: {
        type: {
          equals: SupportPostType.FAQ_HEADER
        },
        language: {
          equals: locale === 'en-GB' ? LanguageEnum.EN : LanguageEnum.DA
        }
      },
      firstFAQHeaderEntries: 1,
      skipFAQHeaderEntries: 0,
      orderByFAQHeaderEntries: { orderId: 'desc' }
    }
  });

  return {
    data,
    loading
  };
}

export default useFAQSupportPosts;
